import React ,{useEffect}from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import "./components/css/style.css";
import Homepage from "./components/Homepage/Homepage";
import Product from "./components/Product/Product";
import Partnership from "./components/Partnership/Who_we_are";
import Contact from "./components/Contactus/Contact";
import Policies from "./components/Policies/Policies";
import Footer from "./components/Footer/Footer";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
function App() {
  return (
    <Router basename="/">
      <ScrollToTop />
      <Routes>
        <Route
          path="/"
          exact
          element={
            <>
              <Navbar />
              <Homepage />
              <Footer />
            </>
          }
        />
        <Route
          path="/products"
          element={
            <>
              <Navbar />
              <Product />
              <Footer />
            </>
          }
        />
        <Route
          path="/who-we-are"
          element={
            <>
              <Navbar />
              <Partnership />
              <Footer />
            </>
          }
        />
        <Route
          path="/policies"
          element={
            <>
              <Navbar />
              <Policies />
              <Footer />
            </>
          }
        />
        <Route
          path="/contact-us"
          element={
            <>
              <Navbar />
              <Contact />
              <Footer />
            </>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
