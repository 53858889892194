import React from 'react';
import kycAndAmlPdf from '../../components/Policies/KYC_Guidelines_AML.pdf';
import fairPracticesCodePdf from '../../components/Policies/Fair_Practices_Code.pdf';
import interestRatePolicy from '../../components/Policies/Rinkpi_Interest_rate_Policy_02.pdf';
import codeOfConductPolicy from '../../components/Policies/Code_of_Conduct.pdf';
import refundPolicy from "../../components/Policies/Refund_Policy_V1.pdf";

const Policies = () => {
  return (
    <>
      <section className="policies-header my-5">
        <div className="row align-items-center">
          <p className="about-heading">
            <span>Our Policies</span>
          </p>
        </div>
      </section>

      <div className="container shadow">
        <ul
          className="nav nav-tabs nav-justified mb-3 "
          id="ex1"
          role="tablist"
        >
          <div className="row py-4">
            <div className="col-md-3 ">
              <li className="nav-item " role="presentation">
                <a
                  className="nav-link active border"
                  id="ex3-tab-1"
                  data-bs-toggle="tab"
                  href="#ex3-tabs-1"
                  role="tab"
                  aria-controls="ex3-tabs-1"
                  aria-selected="true"
                >
                  Disclaimer
                </a>
              </li>
            </div>
            <div className="col-md-3">
              <li className="nav-item " role="presentation">
                <a
                  className="nav-link border"
                  id="ex3-tab-2"
                  data-bs-toggle="tab"
                  href="#ex3-tabs-2"
                  role="tab"
                  aria-controls="ex3-tabs-2"
                  aria-selected="false"
                >
                  Terms of Use
                </a>
              </li>
            </div>
            <div className="col-md-3">
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link border"
                  id="ex3-tab-3"
                  data-bs-toggle="tab"
                  href="#ex3-tabs-3"
                  role="tab"
                  aria-controls="ex3-tabs-3"
                  aria-selected="false"
                >
                  KYC and AML
                </a>
              </li>
            </div>
            <div className="col-md-3">
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link border"
                  id="ex3-tab-4"
                  data-bs-toggle="tab"
                  href="#ex3-tabs-4"
                  role="tab"
                  aria-controls="ex3-tabs-4"
                  aria-selected="false"
                >
                  Fair Practices Code
                </a>
              </li>
            </div>
            <div className="col-md-3 my-2">
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link border"
                  id="ex3-tab-7"
                  data-bs-toggle="tab"
                  href="#ex3-tabs-7"
                  role="tab"
                  aria-controls="ex3-tabs-7"
                  aria-selected="false"
                >
                  Privacy Policy
                </a>
              </li>
            </div>
            <div className="col-md-3">
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link border"
                  id="ex3-tab-5"
                  data-bs-toggle="tab"
                  href="#ex3-tabs-5"
                  role="tab"
                  aria-controls="ex3-tabs-5"
                  aria-selected="false"
                >
                  Customer Terms and Conditions
                </a>
              </li>
            </div>
            <div className="col-md-3">
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link border"
                  id="ex3-tab-6"
                  data-bs-toggle="tab"
                  href="#ex3-tabs-6"
                  role="tab"
                  aria-controls="ex3-tabs-6"
                  aria-selected="false"
                >
                  Grievance Redressal Mechanism
                </a>
              </li>
            </div>
            <div className="col-md-3">
              <li className="nav-item" role="presentation">
                <a
                    className="nav-link border"
                    id="ex3-tab-8"
                    data-bs-toggle="tab"
                    href="#ex3-tabs-8"
                    role="tab"
                    aria-controls="ex3-tabs-7"
                    aria-selected="false"
                >
                  Interest Rate Policy
                </a>
              </li>
            </div>
            <div className="col-md-3">
              <li className="nav-item" role="presentation">
                <a
                    className="nav-link border"
                    id="ex3-tab-10"
                    data-bs-toggle="tab"
                    href="#ex3-tabs-10"
                    role="tab"
                    aria-controls="ex3-tabs-10"
                    aria-selected="false"
                >
                  Refund Policy
                </a>
              </li>
            </div>
            <div className="col-md-3">
              <li className="nav-item" role="presentation">
                <a
                    className="nav-link border"
                    id="ex3-tab-9"
                    data-bs-toggle="tab"
                    href="#ex3-tabs-9"
                    role="tab"
                    aria-controls="ex3-tabs-7"
                    aria-selected="false"
                >
                  Code Of Conduct Policy
                </a>
              </li>
            </div>
            {/* <div className="col-md-3">
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link"
                  id="ex3-tab-8"
                  data-bs-toggle="tab"
                  href="#ex3-tabs-8"
                  role="tab"
                  aria-controls="ex3-tabs-8"
                  aria-selected="false"
                >
                  Grievance Redressal Mechanism
                </a>
              </li>
            </div> */}
          </div>
        </ul>

        <div className="tab-content mt-4" id="ex2-content">
          <div
            className="tab-pane fade show active px-5 mx-5 pb-5"
            id="ex3-tabs-1"
            role="tabpanel"
            aria-labelledby="ex3-tab-1"
          >
            <p className="text-center text-decoration-underline fs-2 mb-5">
              Disclaimer
            </p>
            <p>
              The information contained in this website is for general
              information purposes only. The information is provided by Rinkpi
              Finance & Consultants and while we endeavor to keep the
              information up to date and correct, we make no representations or
              warranties of any kind, express or implied, about the
              completeness, accuracy, reliability, suitability or availability
              with respect to the website or the information, products,
              services, or related graphics contained on the website for any
              purpose. Any reliance you place on such information is therefore
              strictly at your own risk.
            </p>
            <p>
              In no event will we be liable for any loss or damage including
              without limitation, indirect or consequential loss or damage, or
              any loss or damage whatsoever arising from loss of data or profits
              arise out of, or in connection with, the use of this website.
            </p>
            <p>
              Through this website you are able to link to other websites which
              are not under the control of Rinkpi Finance & Consultants. We have
              no control over the nature, content and availability of those
              sites. The inclusion of any links does not necessarily imply a
              recommendation or endorse the views expressed within them.
            </p>
            <p>
              Every effort is made to keep the website up and running smoothly.
              However, Rinkpi Finance & Consultants takes no responsibility for,
              and will not be liable for, the website being temporarily
              unavailable due to technical issues beyond our control.
            </p>
          </div>
          <div
            className="tab-pane fade px-5 mx-5 pb-5"
            id="ex3-tabs-2"
            role="tabpanel"
            aria-labelledby="ex3-tab-2"
          >
            <p className="text-center text-decoration-underline fs-2 mb-5">
              TERM OF USE
            </p>
            <p>
              The website rinkpifinance.com (the “Website”) is owned, provided
              and maintained by RINKPI FINANCE & CONSULTANTS PRIVATE Limited, a
              non-banking financial company incorporated under the Companies Act
              1956 having its registered office at Flat No.1208 D, Vijaya
              Building, Barakhamba Road,Connaught Place New Delhi Central Delhi
              DL 110001 IN. (“Company”). In these Terms of Use (“ToU”), “we”,
              “our” and “us” cumulatively means ‘rinkpifinance.com’ and the
              Company being the owner of this Website. “You” and “your” means
              any person who accesses and uses this website.
            </p>
            <br />
            <strong>GENERAL TERMS</strong>
            <br />
            <p>
              Access to and use of this Website is subject to these ToU, our
              Privacy Policy and such other policies that are on our Website. By
              accessing and using this Website you agree to be bound by and to
              act in accordance with these ToU and our policies. If you do not
              agree to these ToU or our policies, you are not permitted to
              access and use this Website and you should cease such access
              and/or use immediately. If you breach any term of these ToU, your
              right to access and use this Website shall cease immediately. We
              reserve the right to amend these ToU from time to time without
              notice by amending this page. The amended ToU will be effective
              from the date they are posted on this Website. As these ToU may be
              amended from time to time, you should check them whenever you
              visit this Website. Your continued use of this Website will
              constitute your acceptance of the amended ToU.
            </p>
            <strong>ELIGIBILITY</strong>
            <br />
            <p>
              The Service is not available to minors under the age of 18,
              individuals / entities who do not have the capacity to contract or
              to any users suspended or removed from the system by
              rinkpifinance.com for any reason. Additionally, users are
              prohibited from selling, trading, or otherwise transferring their
              accounts to another party.
            </p>
            <strong>RIGHT TO USE PERSONAL / CONTACT DETAILS</strong>
            <br />
            <p>
              You agree that we reserve the right to make your details available
              to any of our authorized members/agents or partners and you may be
              contacted by them for information and for sales through email,
              telephone, app notifications and/or SMS. You agree to receive
              promotional materials and/or special offers from us through email
              or SMS or any other mode of communication. If you disagree with
              this ToU, please do not use this Website. Please be aware that
              information provided on this Website is provided for general
              information purposes only and is provided so that you can avail
              the product and/or service that you feel is appropriate to meet
              your needs. You should always check the suitability, adequacy and
              appropriateness of the product and/or service that is of interest
              to you and it is your sole decision whether to obtain or refrain
              from obtaining any product and/or service. If you are in any doubt
              as to the suitability, adequacy or appropriateness of any product
              and/or service referred to on this Website, we suggest that you
              seek independent professional advice before you obtain any product
              and/or service via this Website. Please be aware that nothing on
              this Website is, or shall be deemed to constitute, an offer by us
              or any third party to sell to you any product and/or service or to
              enter into any contract with you in respect of any product and/or
              service. By submitting your details, you are making an offer to
              obtain the relevant product and/or service that may be accepted or
              rejected. The contract for the product and/or service will only be
              concluded once your offer has been accepted. You will receive
              confirmation when your offer has been accepted. We aim to provide
              uninterrupted access to this Website but we give no warranty as to
              the uninterrupted availability of this Website. We reserve the
              right to suspend, restrict or terminate your access to this
              Website at any time. We reserve the right to add, amend, delete,
              edit, remove or modify any information, content, material or data
              displayed on this Website and without notice from time to time.
            </p>
            <strong>PERMISSION TO USE</strong>
            <br />
            <p>
              Any unauthorized use by you or any illegal or unlawful activity by
              you in or for this Website shall terminate the permission or
              license granted to you by us to use this Website and may give rise
              to a claim for damages and/or be considered a criminal offense.
              You may operate a link to this Website provided you do so in a way
              that is fair and legal and does not damage our reputation or take
              advantage of it, as determined by us. You must not operate a link
              to this Website in such a way as to suggest or imply any form of
              association, approval or endorsement by us, unless otherwise
              agreed between you and us through a separate legal agreement. We
              reserve the right to require you to immediately remove any link to
              this Website at any time and we may withdraw any linking
              permission at any time.
            </p>
            <br />
            <strong>INTELLECTUAL PROPERTY RIGHTS</strong>
            <p>
              The copyright in the information, content, material or data
              displayed on this Website belongs to us or our licensors or our
              licensees or authorized or permitted agents/members. You may
              temporarily print, copy, download or store extracts of
              information, content, material or data displayed on this Website
              for your own personal use but should not use for any commercial
              purpose. This Website contains material which is owned by or
              licensed to us and are protected by copyright. This material
              includes, but is not limited to, data, documents, information,
              details, design, layout, appearance and graphics; logos, business
              names, trading names, service marks and trade-marks, internet
              domain names, moral rights, rights specifications, know how,
              processes and business methods; software programs (in all cases
              whether registered or unregistered and including all rights to
              apply for registration) and all such rights are reserved.
              Reproduction of any material of our Website is prohibited for any
              commercial use. Business Secret / Protection of Business Method:
              You must not transmit, or input into the Website, any: files that
              may damage any other person's computing devices or software,
              content that may be offensive, or material or Data in violation of
              any law (including Data or other material protected by copyright
              or trade secrets/ business method on which you do not have the
              right to use)
            </p>
            <br />
            <strong>BUSINESS SECRET / PROTECTION OF BUSINESS METHOD</strong>
            <br />
            <p>
              You must not transmit, or input into the Website, any files that
              may damage any other person's computing devices or software,
              content that may be offensive, or material or Data in violation of
              any law (including Data or other material protected by copyright
              or trade secrets/ business method on which you do not have the
              right to use).
            </p>
            <br />
            <strong>OUR LIMITED LIABILITIES</strong> <br />
            <p>
              We use reasonable endeavors to ensure that the data, material and
              information on this Website is accurate and to correct any errors
              or omissions as soon as practicable after being notified of them.
              However, we are not guaranteeing that the data, material and
              information on this Website are accurate or that there are no
              errors or omissions in the data, material and information. We do
              not guarantee the accuracy of, and disclaim all liability for any
              errors or other inaccuracies relating to the information and
              description of the content, products, and services. All such
              information and services are provided "AS IS" without warranty of
              any kind. We disclaim that all warranties and conditions that this
              Website, its services or any email sent from us, our affiliates,
              and / or their respective agents are free of viruses or other
              harmful components. We are not responsible for any losses or
              damages arising from an inability to access the Website, from any
              use of the Website or from reliance on the data transmitted using
              the Website where such losses or damages are caused by any event
              beyond our reasonable control including as a result of the nature
              of electronic transmission of data over the internet. We are not
              responsible or liable for any direct and indirect losses or
              damages suffered or incurred by you which were not foreseeable by
              us when you accessed or used the Website. Please be aware that by
              submitting your details, you are making an offer to obtain the
              relevant product or service from us and our affiliates on the
              terms and conditions that may be accepted or rejected. The
              contract for the product or service will only be concluded once
              your offer has been accepted. You will receive confirmation when
              your offer has been accepted. In case your offer for any product
              and/or service is rejected even after acceptance for any reason,
              we are not responsible or liable for any losses or damages
              suffered or incurred by you in the process. You may also be able
              to access user reviews directly on the Website. The views
              expressed therein do not represent our views or the views of our
              affiliates and we are not responsible or liable for the accuracy
              or content of any such views or expressions. We are not
              responsible or liable for any loss or damage you may suffer or
              incur in connection with your use of user reviews on the Website.
              Please be aware that the information and descriptions of products
              and/or services on this Website may not represent the complete
              descriptions of all the features and terms and conditions of those
              products and/or services. You must ensure that you carefully read
              all the features and terms and conditions of any product and/or
              service before availing it. If you apply for and obtain any
              product and/or service through this Website, it is your
              responsibility to ensure that you understand and agree with the
              prescribed terms and conditions before entering into a contract to
              obtain that product or service. Any views, opinions, advice or
              assistance which is given or provided to you by a third party
              after you have used this Website do not represent our views,
              opinions, advice or assistance and are not checked, monitored,
              reviewed, verified or endorsed by us. We do not endorse, recommend
              or take responsibility for any third party who provides you with
              any views, opinions advice or assistance. You act or refrain from
              acting on any third party's views, opinions, advice or assistance
              at your sole risk and sole discretion and you are solely
              responsible for any decision to act or refrain from acting on such
              views, opinions, advice or assistance. We are not responsible or
              liable for any loss or damage you may suffer or incur in
              connection with such views, opinions, advice or assistance
              including in relation to their accuracy, truthfulness or
              completeness or for any acts, omissions, errors or defaults of any
              third party in connection with such views, opinions, advice or
              assistance. In no event shall we and/or our affiliates be liable
              for any direct, indirect, punitive, incidental, special, or
              consequential damages arising out of, or in any way connected
              with, your access to, display of or use of this Website or with
              the delay or inability to access, display or use this Website
              (including, but not limited to, your reliance upon opinions
              appearing on this Website; any computer viruses, information,
              software, linked sites, products, and services obtained through
              this Website; or otherwise arising out of the access to, display
              of or use of this Website) whether based on a theory of
              negligence, contract, tort, strict liability, or otherwise. Please
              be aware that there may be other products or services available in
              the market which are not shown on this Website and which may be
              more appropriate or suitable for you than those shown on this
              Website.
            </p>
            <br />
            <strong>RESPONSIBILITIES OF USERS</strong>
            <br />
            <p>
              You must take all reasonable precautions (including using
              appropriate virus checking software) to ensure that any
              information, content, material or data you provide is free from
              viruses, spyware, malicious software, trojans, worms, logic bombs
              and anything else which may have a contaminating, harmful or
              destructive effect on any part of this Website or any other
              technology. You may complete a registration process as part of
              your use of this Website which may include the creation of a
              username, password and/or other identification information. Any
              username, password and/or other identification information must be
              kept confidential by you and must not be disclosed to, or shared
              with, anyone. Where you do disclose to or share with anyone your
              username, password and/or other identification information, you
              are solely responsible for all activities undertaken on this
              Website using your username, password and/or other identification
              information. It is your responsibility to check and ensure that
              all information, content, material or data you provide on this
              Website is correct, complete, accurate and not misleading and that
              you disclose all relevant facts. We do not accept any
              responsibility or liability for any loss or damage you may suffer
              or incur if any information, content, material or data you provide
              on this Website is not correct, complete and accurate or if it is
              misleading or if you fail to disclose all relevant facts. You must
              get permission from any other person about whom you propose to
              provide information before you provide it. In submitting any other
              person's details, you are confirming to us that you have their
              permission to do so and that they understand how their details
              will be used. You are solely liable for any impersonation or fraud
              that you may commit while submitting any information on this
              Website and the resultant damage or injury that such impersonation
              or fraud may cause to us or to any third party. We, and such
              affected third parties shall have the rights to initiate such
              legal action against you as deemed fit. You undertake to comply
              with all laws, rules and regulations in force at all times during
              the subsistence of these terms within the territory of India. You
              are solely responsible and liable for your conduct on this Website
              and for your user content.
            </p>
            <br />
            <strong>INDEMNITY</strong>
            <br />
            <p>
              You agree that you will be liable to us for any damage, loss,
              claim, demand, liability or expense that we may suffer or incur
              arising out of or in connection with your conduct on this Website
              and/or your user content. You agree to indemnify us for and hold
              us and our officers, directors, agents, subsidiaries, joint
              ventures, and employees harmless from any claims, causes of
              action, damages, losses, demands, liabilities, recoveries, fines,
              penalties or other costs or expenses of any kind or nature,
              including reasonable attorneys' fees arising out of or related to
              your breach of this ToU or arising out of your violation of any
              law or the rights of a third party, or your use/conduct on this
              Website.
            </p>
            <br />
            <strong>RECORDING YOUR CALLS</strong>
            <br />
            <p>
              Telephone calls that you may make to or receive from our customer
              service help lines may be monitored and/or recorded. This will
              help us to train our staff and improve our service to you. A
              recording will only be used under proper and careful supervision.
            </p>
            <br />
            <strong>COMPLAINTS/GRIEVANCES</strong>
            <br />
            <p>
              Our aim is to provide you with excellent service at all times. If
              you are unhappy with our service for any reason or have complaint
              or grievance of any kind, you can inform us about the same by
              sending email at grievance@rinkpifinance.com We will aim to
              resolve your complaint as soon as possible and if we are not able
              to do so within seven (7) working days, we will provide you with
              an acknowledgement. After we have had an opportunity to
              investigate your concerns, we will issue you with a final
              response. If you remain dissatisfied with our response or in case
              of any kind of disputes, differences, controversies relating to or
              arising out of our products or services or acts or relating to the
              use of this Website all such disputes, differences and
              controversies shall be finally, exclusively and conclusively
              settled by Arbitration in accordance with the Rules of Arbitration
              under the provisions of Arbitration and Conciliation Act, 1996.
              The Arbitration shall be conducted in English Language and the
              seat for such arbitration shall be New Delhi, India. You agree
              that the award of the arbitral tribunal shall be final and binding
              on the parties to the dispute and the court of New Delhi will have
              appropriate jurisdiction for enforcement of the award of the
              arbitral tribunal. The cost of the Arbitration along with an
              enforcement cost (if applicable) shall be borne by the respective
              parties.
            </p>
            <br />
            <strong>PRIVACY</strong>
            <p>
              We are committed to protecting your privacy and we treat your
              privacy very seriously. We process information about you in line
              with our Privacy Policy. By using this Website, you agree to the
              way in which we process and deal with your personal information.
              We may disclose your personal information or access your account
              if required to do so by law, any court, the Reserve Bank of India,
              or any other applicable regulatory, compliance, Governmental or
              law enforcement agency.
            </p>
            <br />
            <strong>MISCELLANEOUS</strong>
            <br />
            <p>
              This Website is only intended for use by residents of India. We
              make no warranty or representation that any product or service
              referred to on this Website and/or any service we provide is
              available or otherwise appropriate for use outside India. If you
              choose to use this Website from locations outside India, you do so
              at your sole risk and you are responsible for compliance with all
              applicable local laws. If any provision of these ToU is held to be
              unlawful, invalid or unenforceable, that provision shall be deemed
              deleted from these ToU and the validity and enforceability of the
              remaining provisions of these ToU shall not be affected. These ToU
              constitute the entire agreement between you and us relating to
              your access to and use of this Website and supersedes any prior
              agreements (including any previous terms of use of this Website).
              No failure or delay by us in exercising any right under these ToU
              will operate as a waiver of that right nor will any single or
              partial exercise by us of any right preclude any further exercise
              of any right.
            </p>
            <br />
            <strong>GOVERNING LAW</strong>
            <p>
              These ToU and your access to and use of this Website shall be
              governed by and interpreted in accordance with Indian laws. Each
              of you and us submits to the exclusive jurisdiction of the courts
              of New Delhi, India in connection with these ToU and your access
              to and use of this Website (including any claims or disputes).
            </p>
            <br />
            <strong>SURVIVAL OF TERMS</strong>
            <p>
              Notwithstanding any other provisions of this ToU, or any general
              legal principles to the contrary, any provision of this ToU that
              imposes or contemplates continuing obligations on a party will
              survive the expiration or termination of this ToU.
            </p>
            <br />
            <strong>Disclaimer</strong>
            <p>
              The information contained in this website is for general
              information purposes only. The information is provided by Rinkpi
              Finance & Consultants and while we endeavor to keep the
              information up to date and correct, we make no representations or
              warranties of any kind, express or implied, about the
              completeness, accuracy, reliability, suitability or availability
              with respect to the website or the information, products,
              services, or related graphics contained on the website for any
              purpose. Any reliance you place on such information is therefore
              strictly at your own risk.
            </p>
            <br />
            <p>
              In no event will we be liable for any loss or damage including
              without limitation, indirect or consequential loss or damage, or
              any loss or damage whatsoever arising from loss of data or profits
              arising out of, or in connection with, the use of this website.
            </p>
            <br />
            <p>
              Through this website you are able to link to other websites which
              are not under the control of Rinkpi Finance & Consultants. We have
              no control over the nature, content and availability of those
              sites. The inclusion of any links does not necessarily imply a
              recommendation or endorse the views expressed within them.
            </p>
            <br />
            <p>
              Every effort is made to keep the website up and running smoothly.
              However, Rinkpi Finance & Consultants takes no responsibility for,
              and will not be liable for, the website being temporarily
              unavailable due to technical issues beyond our control.
            </p>
            <br />
          </div>
          <div
            className="tab-pane fade px-5 mx-5 pb-5"
            id="ex3-tabs-3"
            role="tabpanel"
            aria-labelledby="ex3-tab-3"
          >
            {/* ///////////3rd tab content//////////// */}
            <a href={kycAndAmlPdf} target="_blank"
               rel="noreferrer">
              <p className="text-center text-decoration-underline fs-2 mb-20"> Download KYC and AML</p>
            </a>
          </div>
          <div
            className="tab-pane fade mx-5 px-5 pb-5"
            id="ex3-tabs-4"
            role="tabpanel"
            aria-labelledby="ex3-tab-4"
          >
            <a href={fairPracticesCodePdf} target="_blank"
               rel="noreferrer">
              <p className="text-center text-decoration-underline fs-2 mb-20"> Download Fair Practices Code</p>
            </a>
          </div>
          <div
            className="tab-pane fade px-5 mx-5 pb-5"
            id="ex3-tabs-5"
            role="tabpanel"
            aria-labelledby="ex3-tab-5"
          >
            <p className="text-center text-decoration-underline fs-2 mb-5">
              <strong>Customer Terms and Conditions</strong>
            </p>
            <p>
              For rinkpifinance.com (owned and operated by Rinkpi Finance &
              Consultants Private Limited)
            </p>
            <p>
              I/We confirm that we have carefully read and fully understood all
              the terms of use of availing financing that are listed on
              rinkpifinance.com website of RINKPI FINANCE & CONSULTANTS PRIVATE
              LIMITED at Terms and Conditions & Privacy Policy and applicable to
              any loan availed through the company’s website referred as the
              “Company’ or “Lender” or “rinkpifinance.com”. I understand that
              www.rinkpifinance.com or rinkpifinance.com represent the
              non-banking finance company ‘Rinkpi Finance & Consultants Private
              Limited’.
            </p>
            <p>
              I/We accept the terms & conditions unconditionally and agree that
              these terms and conditions may be amended or modified at any time
              and I/we will be bound by the amended terms & conditions that are
              in force. I/we shall keep myself/ ourselves updated on all such
              updates.
            </p>
            <p>
              I/We understand that sanction of the loan(s) is at the sole
              discretion of the Company and is subject to my/our execution of
              necessary documents, providing any security (where applicable) and
              other closing formalities to the satisfaction of the Lender. I/We
              agree that the Company at its sole discretion may reject my
              application without providing any reasons, and that I/We reserve
              no right to appeal against this decision.
            </p>
            <p>
              I/We understand that it is my/our responsibility to fully read and
              understand the terms and conditions of the loan documents and make
              ourselves fully aware of all the obligations that we will have as
              a borrower under the loan documents. It will be solely at our
              discretion and our informed decision to proceed or not proceed
              with availing of the loan from the Company basis the terms and
              conditions of the loan documents.
            </p>
            <p>
              I agree that I shall not copy, reproduce, sell, redistribute,
              publish, enter into a database, display, perform, modify,
              transmit, license, create derivatives from, transfer or in any way
              exploit any part of any information, content, materials, services
              available from or through the Company website, except that which I
              may download for my own personal, non-commercial use.
            </p>
            <p>
              I understand and accept that all information submitted by me
              through rinkpifinance.com shall be deemed the property of the
              Company, and the Company shall be free to use any ideas, concepts,
              know-how or techniques provided by me at rinkpifinance.com, in any
              manner whatsoever. On initiating a contact through
              rinkpifinance.com I agree to being contacted by the Company or any
              other entities with whom the Company has entered into an
              arrangement.
            </p>
            <p>
              I/We declare that I am/we are citizens of/resident in India and
              I/we declare, warrant and represent that all the particulars and
              information and details given / filled in this application form
              and documents given by me/us to are true, correct and accurate and
              I/we and have the capacity to contract as specified under the
              Indian Contract Act, 1872, while availing the Services offered
              herein via rinkpifinance.com and I/we have not withheld /
              suppressed any material information. I/We undertake to furnish any
              additional information/ documents that may be required by
              rinkpifinance.com from time to time. The company reserves the
              right to retain the photograph & all the documents submitted with
              the applications & may not return the same to the applicant.
            </p>
            <p>
              I/We confirm that there are no insolvency proceedings against
              me/us nor have I/we ever been adjudicated insolvent. I/We also
              undertake to inform the Company of changes in my/our
              occupation/employment and to provide any further information that
              the Company may require. I/We shall advise the Company on the
              change in my/our resident status.
            </p>
            <p>
              I/We agree that my/our loan will not be used for any speculative
              or anti-social purpose.
            </p>
            <p>
              As a pre-condition, relating to grant of the loans/advances/credit
              facilities by rinkpifinance.com to me/us, I/We agree and give
              consent for the disclosure by rinkpifinance.com of: (I)
              information and data relating to me/us and our directors and group
              companies; (II) the information or data relating to any credit
              facility availed of/to be availed, by me/us; and (III) default, if
              any, committed by me/us, in discharge of any our obligations, to
              all or any third parties as may be deemed necessary at the sole
              discretion of rinkpifinance.com and/or as may be required under
              the applicable laws in force and/or upon the direction of
              statutory/ governmental authorities.
            </p>
            <p>
              The Company may, as it reasonably deems appropriate and necessary,
              disclose and furnish information as set forth above to CIBIL
              and/or any other agency authorized in this behalf by the Reserve
              Bank of India. The Borrower hereby agrees that the Company shall
              be entitled to exchange information regarding our accounts with
              other financial institutions or banks, as and when required in
              accordance with Applicable Law and regulations.
            </p>
            <p>
              I/We undertake and acknowledge that: (a) For the purpose of
              assessing our credit worthiness, the Company is authorized to pull
              from CIBIL and/or any other agency authorized by RBI, data of the
              applicant(s), directors, group companies, and any other
              individuals who are involved in the said business, on behalf of
              which this loan application is made; (b) CIBIL and any other
              agency so authorized may use, process the said information and
              data disclosed by the Company in the manner as deemed fit by them;
              and (c) CIBIL, and any other agency so authorized may furnish for
              consideration, the processed information and data or products
              thereof prepared by them, to banks/financial institutions and
              other credit grantors or registered users, as may be specified by
              the RBI in this behalf.
            </p>
            <p>
              I/We further authorize the Company to keep me/us informed (vide
              telephone, SMS, mail, E-mail, notification on mobile app etc.) of
              all promotional schemes, application status, my account
              information, notices and/or activities etc.
            </p>
          </div>
          <div
            className="tab-pane fade mx-5 px-5 pb-5"
            id="ex3-tabs-6"
            role="tabpanel"
            aria-labelledby="ex3-tab-6"
          >
            <p className="text-center text-decoration-underline fs-3 mb-5">
              Grievance Redressal Mechanism
            </p>

            <p>
              In case of any grievances against Rinkpi Finance & Consultants
              Private Limited (the “Company”), you may reach out to our
              representatives below at any time between 10:00 am and 6:00 pm
              Monday to Friday except public holidays
            </p>
            <p className="text-Right fs-3 mb-5">Grievance Redressal Officer</p>
            <p>
              Customers are requested to address all their grievances at the
              first instance to the Grievance Redressal Officer. The contact
              details of the Grievance Redressal Officer are as provided below.
            </p>

            <p>
              Mr. Manoj Jain <br />
              Address: Flat No.1208 D, Vijaya Building, Barakhamba
              Road,Connaught Place New Delhi Central Delhi DL 110001 IN <br />
              Email ID: grievance@rinkpifinance.com
            </p>

            <p>
              The Grievance Redressal Officer may be reached on the email
              provided above anytime between 10:00 am and 6:00 pm on weekdays
              except public holidays. The Grievance Redressal Officer shall
              endeavor to resolve the grievance within a period of fifteen days
              from the date of receipt of a grievance.
            </p>

            <p className="text-Right fs-3 mb-5">Nodal Officer</p>
            <p>
              If the customer does not receive a response from the Grievance
              Redressal Officer within 15 days of making a representation, or if
              the customer is not satisfied with the response received from the
              Grievance Redressal Officer, the customer may reach the Nodal
              Officer on the number below anytime between 10:00am and 6:00 pm on
              weekdays except public holidays or write to the Nodal Officer at
              the e-mail address below. The contact details of our Nodal Officer
              are provided below.
            </p>
            <p>
              Mr. Manoj Jain <br />
              Address: Flat No.1208 D, Vijaya Building, Barakhamba
              Road,Connaught Place New Delhi Central Delhi DL 110001 IN <br />
              Email ID: info@rinkpifinance.com
            </p>
            <p>
              If the complaint / dispute is not redressed within a period of one
              month, the customer may appeal to the Officer-in-Charge of the
              Regional Office of DNBS of RBI Delhi (please refer{" "}
              <a
                href="https://www.rbi.org.in/Scripts/NBFCCitiChart.aspx"
                target="_blank"
              >
                here
              </a>{" "}
              for complete contact details).
            </p>
            <p className="text-Right fs-3 mb-5">Complaints to Ombudsman</p>
            <p>
              In case the customer does not receive a response from the
              Grievance Redressal Officer or the Nodal Officer within one month
              from the date of making a representation to the Company, or if the
              customer is not satisfied with the response so received, a
              complaint may be made in accordance with the ‘The Reserve Bank –
              Integrated Ombudsman Scheme, 2021’ (“Ombudsman Scheme”) to the
              Ombudsman in whose jurisdiction the office of the Company
              complained against, is located. the RBI ombudsman on the below
              mentioned details.
            </p>
            <p>
              Contact Ombudsman: To Know more about the Ombudsman Scheme{" "}
              <a
                href="https://rbidocs.rbi.org.in/rdocs/content/pdfs/RBIOS2021_121121.pdf"
                target="_blank"
              >
                Click Here
              </a>
              .
            </p>
          </div>
          <div
            className="tab-pane fade px-5 mx-5 pb-5"
            id="ex3-tabs-7"
            role="tabpanel"
            aria-labelledby="ex3-tab-7"
          >
            <p className="text-center text-decoration-underline fs-2 mb-5">
              <strong>Privacy Policy</strong>
            </p>
            <p>
              This privacy policy is applicable to RINKPIFINANCE.COM whereby
              RINKPIFINANCE.COM intends to protect the privacy of its users and
              members on its website being WWW.RINKPIFINANCE.COM. (our
              “Website”) and the privacy of the data provided by the user and
              members to RINKPIFINANCE.COM from time to time.
            </p>
            <p>
              The privacy of our Website users and members, whether you are our
              former or existing registered user or or merely a visitor to our
              Website, is important to us and we are strongly committed to your
              right to privacy and to keeping your personal and other
              information secure.
            </p>
            <p>
              We encourage you to read our privacy statement to understand what
              types of personally identifiable information we collect and how we
              use this information.
            </p>

            <p className="text-left fs-2 mb-5">
              WHAT TYPE OF INFORMATION DOES RINKPIFINANCE.COM COLLECT FROM YOU?
            </p>
            <p className="fs-5">
              <strong> PERSONAL INFORMATION</strong>
            </p>
            <p>
              For the purpose of providing you the required services, we require
              you to enroll yourself on our Website and get yourself registered.
            </p>
            <ol>
              <li>
                <p>
                  {" "}
                  If you choose to become a registered member of our Website,
                  you are required to share your name, e-mail address, gender,
                  date of birth, postal address, phone number, a unique login
                  name, password, password validation, income tax details,
                  marital status, details, family details, business information
                  and other details shared via application form or via email or
                  via any other medium (the “Personally Identifiable
                  Information”).
                </p>
              </li>
              <li>
                <p>
                  {" "}
                  RINKPIFINANCE.COM may also collect other information specific
                  to provide you service each time such as name, bank
                  statements, KYC documents, personal details, business details,
                  income tax details, marital status, family details and other
                  information via application form or via email or via any other
                  electronic medium or via printed form (the “Service
                  Information”).
                </p>
              </li>
              <li>
                <p>
                  Additionally, RINKPIFINANCE.COM collects your other details
                  including but not limited to your feedback, suggestions,
                  views, opinions, etc. shared on our Website (the “User
                  Information”) that may be shared/volunteered by you on our
                  discussion forums or other pages of our Website. Such
                  information being in the public domain is available for access
                  for all our Website's users and visitors. You are advised to
                  exercise discretion before you make such User Information
                  public, as such information is vulnerable to misuse and can be
                  easily misused. RINKPIFINANCE.COM in this regard fully
                  disclaims any liability (ies) or claim(s), which may arise by
                  use/misuse of User Information shared by you, by any third
                  party or any party not known to RINKPIFINANCE.COM.
                </p>
              </li>
              <li>
                <p>
                  {" "}
                  Information exchanged by you with RINKPIFINANCE.COM in the
                  form of written communication, responses to emails, feedback
                  required from you, participation in discussions, etc. is
                  handled with confidentiality and is available for the
                  exclusive use of RINKPIFINANCE.COM.
                </p>
              </li>
              <li>
                <p>
                  {" "}
                  We at RINKPIFINANCE.COM always strive to protect the users'
                  Personally Identifiable Information and Service Information
                  with a degree of care, reasonably expected from us, to prevent
                  the unauthorized use, dissemination, or publication of these
                  information as we protect our own confidential information of
                  a like nature. The information we collect is limited to
                  information provided by you on applications or other web
                  forms. The information gathered is needed to help us
                  understand the needs of the users and the members to provide
                  them with excellent customer service, for internal record
                  keeping, to improve our products, services and customer
                  satisfaction.
                </p>
              </li>
              <li>
                <p>
                  {" "}
                  We at RINKPIFINANCE.COM do not compromise with the security of
                  the users and the members Personally Identifiable Information
                  and Service Information at any level and we implement various
                  security measures to prevent unauthorized access and maintain
                  the safety of the Personally Identifiable Information and
                  Service Information of the users when they enter, submit, or
                  access our Website. The Personally Identifiable Information
                  and Service Information you provide to us will be retained for
                  minimum [one year] or such period as may be statutorily or
                  contractually required.
                </p>
              </li>
              <p>
                Please note that RINKPIFINANCE.COM DOES NOT trade or sell your
                Personal Information in any manner, except as specified herein,
                or if express consent is sought from you.
              </p>
            </ol>
            {/* /////////////////////////////////////////////// */}
            <p className="fs-5">
              <strong> EXCEPTIONS</strong>
            </p>
            <p>
              We will not voluntarily disclose your information with us but in
              exceptional circumstances we may do so if we are required by a
              Court Order, by Government Authorities, by Law Enforcement
              Authorities or by other legal processes or where it becomes
              necessary to do so to protect the rights or property of Rinkpi
              Finance & Consultants Private Limited or its affiliates.
            </p>
            {/* /////////////////////////////////////////////// */}
            <p className="fs-5">
              <strong> NON-PERSONAL INFORMATION</strong>
            </p>
            <p>
              RINKPIFINANCE.COM also collects certain other information from you
              on your visit to our Website such as your information about your
              operating system, browser type, the URL of the previous web site
              you visited, your internet service provider and your IP Address
              (the “Non-personal Information”). This information cannot be
              easily used to personally identify you. We use Non-personal
              Information for the purposes including but not limited to
              troubleshoot connection problems, administer the Website, analyze
              trends, gather demographic information, to ascertain how our
              visitors use our website, including information relating to the
              frequency of visits to our website, average length of visits,
              pages viewed during a visit, ensuring compliance with the
              applicable law, cooperating with law enforcement activities, etc.
              This information is used to improve the site content and
              performance. You also agree that RINKPIFINANCE.COM may gather
              usage statistics and usage data from your use of our Website to
              evaluate your use of our products/services, to improve our
              products/services, Website content and to ensure that you are
              complying with the terms of the applicable agreements between you
              and RINKPIFINANCE.COM.
            </p>
            <p>
              The statistics and data collected may or may not be aggregated.
              These statistics contain no information that can distinctly
              identify you.
            </p>
            <p>
              We may in future also share this information with third party
              service providers or third party advertisers to measure the
              overall effectiveness of our online advertising, content,
              programming and for other bonafide purposes, as we may desire. By
              usage of our Website you expressly permit RINKPIFINANCE.COM to
              access such information for one or more purposes deemed fit by
              RINKPIFINANCE.COM.
            </p>
            {/* /////////////////////////////////////////////// */}
            <p className="fs-5">
              <strong>COOKIES</strong>
            </p>
            <p>
              You are also being made aware that non-personal information and
              data may be automatically collected through the standard operation
              of WWW.RINKPIFINANCE.COM internet servers or through the use of
              "cookies" and that WWW.RINKPIFINANCE.COM cannot control the use of
              cookies or the resulting information by advertisers or third
              parties hosting data for WWW.RINKPIFINANCE.COM.
            </p>
            {/* /////////////////////////////////////////////// */}
            <p className="fs-5">
              <strong> SESSION DATA</strong>
            </p>
            <p>
              We automatically log generic information about your computer's
              connection to the Internet, which we call "session data", that is
              anonymous and not linked to any personal information. Session data
              consists of information such as the IP address, operating system,
              type of browser software being used by you and the activities
              conducted by you on our Website. An IP address is a number that
              lets computers attached to the Internet, such as our web servers,
              know where to send data back to the user, such as the pages of the
              site the user wishes to view. We collect session data because it
              helps us analyze things such as the items visitors are likely to
              click on most, the manner in which visitors click preferences on
              our Website, number of visitors surfing to various pages on the
              site, time spent by the visitors on our Website and frequency of
              their visit. It also helps us diagnose problems with our servers
              and lets us better administer our systems. Although such
              information does not identify any visitor personally, it is
              possible to determine from an IP address a visitor's Internet
              Service Provider (ISP), and the approximate geographic location of
              his or her point of connectivity.
            </p>
            {/* /////////////////////////////////////////////// */}
            <p className="fs-5">
              <strong> OTHER</strong>
            </p>
            <p>
              We may from time to time add or enhance products/services
              available on our Website. To the extent these products/services
              are provided to and used by you, we will use the information you
              provide in this regard to facilitate the products/service
              requested. For example, if you email us with a question, we will
              use your email address, name, nature of the question, etc. to
              respond to your question. We may also store and publish such
              information to assist us in making the site better and easier to
              use.
            </p>
            {/* /////////////////////////////////////////////// */}
            <p className="fs-5">
              <strong> PROMOTIONAL INITIATIVES</strong>
            </p>
            <p>
              RINKPIFINANCE.COM may have a presence on social networking
              websites including but not limited to LinkedIn, Facebook, Twitter,
              YouTube and blogs which are promotional and business initiatives
              to connect to a larger group of people. The domain links contained
              therein may either direct you to our Website or request your
              participation by way of feedback, suggestions. RINKPIFINANCE.COM
              in this regard fully disclaims any liability (ies) or claim(s),
              which may arise by use/misuse of your feedback, suggestions,
              views, etc. on any of the aforementioned networking websites or
              blogs, by any third party whether or not known to
              RINKPIFINANCE.COM.
            </p>
            <p className="text-left fs-2 mb-5">
              DOES RINKPIFINANCE.COM PROTECT MY PERSONAL INFORMATION?
            </p>
            <p>
              RINKPIFINANCE.COM is committed to protecting the privacy and the
              confidentiality of your Personal Information. Whenever
              RINKPIFINANCE.COM obtains Personal Information from you, our
              Website uses commercially reasonable efforts and general industry
              standards to protect it from any unauthorized access or
              disclosure. Access to your Personal Information is limited to
              RINKPIFINANCE.COM’s personnel and such authorized third parties
              who may host your data on our behalf or may assist us in providing
              the products and services requested by you. RINKPIFINANCE.COM uses
              its best endeavors to maintain physical, electronic and procedural
              safeguards that aim to protect your information against loss,
              misuse, damage, modification, and unauthorized access or
              disclosure. However, RINKPIFINANCE.COM assumes no liability
              whatsoever for any disclosure of Personal Information due to
              unauthorized third party access or other acts of third parties, or
              any other acts or omissions beyond reasonable control of
              RINKPIFINANCE.COM.
            </p>
            <p className="text-left fs-2 mb-5">
              DOES RINKPIFINANCE.COM SECURE THE CONTENT ITS USERS POST FROM
              OTHER USERS?
            </p>
            <p>
              The content that you store, post, or transmit on or through our
              Website, may be accessed by other users (registered or
              unregistered), including people that you do not know. The
              content/images that you post/share on our Website may be prone to
              misuse by a party not known to you. RINKPIFINANCE.COM in this
              regard fully disclaims any liability (ies) or claim(s), which may
              arise by use/misuse of such content, by any third party or any
              party not known to RINKPIFINANCE.COM. We suggest that you exercise
              care and caution in communicating with other users and only
              disclose your information to other users that you know to be
              trustworthy. You should not assume that your content will be kept
              private.
            </p>
            <p className="text-left fs-2 mb-5">
              HOW DOES RINKPIFINANCE.COM USE THE INFORMATION THAT IT COLLECTS?
            </p>
            <p>
              Any information share by you is used by us and/or authorized third
              parties/service partners to support your interaction with us, to
              offer you services in the best possible manner and to contact you
              again about other services and products that we offer. By
              submitting your Personal Information to RINKPIFINANCE.COM, you
              expressly acknowledge and consent to RINKPIFINANCE.COM to use such
              information and to process the same in a manner deemed fit by
              RINKPIFINANCE.COM. This may involve conducting data analysis,
              research based on reviews about deals, transfer of your Personal
              Information to third party experts for the purpose of outsourcing
              services offered to you by RINKPIFINANCE.COM, etc.
            </p>
            <p className="text-left fs-2 mb-5">
              WHAT SECURITY PRECAUTIONS ARE IN PLACE TO PROTECT AGAINST THE
              LOSS, MISUSE, OR ALTERATION OF YOUR INFORMATION?
            </p>
            <p>
              RINKPIFINANCE.COM makes every reasonable effort to preserve the
              privacy and confidentiality of your information shared with us. We
              implement standard measures to protect against unauthorized access
              to and unlawful interception of Personal Information. However, no
              Internet site can fully eliminate security risks.
            </p>
            <p>
              We reserve the right to disclose information shared by you without
              your consent (express or implied) and without any liabilities to
              you when required or permitted by law and we have a good-faith
              belief that (i) such action is necessary to comply with an
              appropriate law enforcement investigation, current judicial
              proceeding, a court order or legal process served on us or (ii) to
              conform to the legal requirements, compliance/reporting to
              administrative and judicial authorities, as may be required (iii)
              protect and defend the rights or property of the website owners of
              RINKPIFINANCE.COM, or the users of RINKPIFINANCE.COM, (iv)
              person(s) or company (ies) who provide certain support services to
              us, and (v) person(s) or company (ies) who share certain
              responsibilities/obligations of RINKPIFINANCE.COM. Further, we
              reserve the right to disclose information shared by you without
              your consent with entities engaged in providing the credit scores
              or credit information related services like CIBIL, etc.
            </p>
            <p>
              If you have any questions about this Privacy Policy, the practices
              of this site, or your dealings with this site, please contact us
              at info@rinkpifinance.com.
            </p>
            <p className="text-left fs-2 mb-5">
              WILL RINKPIFINANCE.COM CHANGE PRIVACY POLICY IN THE FUTURE?
            </p>
            <p>
              RINKPIFINANCE.COM reserves the right to change or update this
              Privacy Policy or any other of our Policies/Practices at any time.
              The same shall be notified to you by posting such changes or
              updated Private Policy on the page. Any changes or updates will be
              effective immediately upon posting to this web site.
            </p>
            <p className="text-left fs-2 mb-5">YOUR CONSENT</p>
            <p>
              By accessing our Website and using it on a continued basis, you
              expressly consent and confirm to RINKPIFINANCE.COM collecting,
              maintaining, using, processing and disclosing your Personal and
              other Information in accordance with this Privacy Policy.
            </p>
            <p className="text-left fs-2 mb-5">GRIEVANCES</p>
            <p>
              In accordance with the relevant provisions of the Information
              Technology Act, 2000 and Rules made thereunder, the name and
              contact details of the Grievance Officer who can be contacted with
              respect to any complaints or concerns including those pertaining
              to breach of RINKPIFINANCE.COM's Terms of Use, Privacy Policy and
              other polices or questions are published as under:
            </p>
            <p>
              Grievance Officer Name: Mr. Manoj Jain <br />
              Email address: info@rinkpifinance.com / info@rinkpifinance.com
              <br />
              The Grievance Officer can be contacted between 10:30 a.m. to 6:00
              p.m. from Monday to Friday except on public holidays.
            </p>
          </div>
          <div
            className="tab-pane fade px-5 mx-5 pb-5"
            id="ex3-tabs-5"
            role="tabpanel"
            aria-labelledby="ex3-tab-5"
          >
            <p className="text-center text-decoration-underline fs-2 mb-5">
              <strong>Customer Terms and Conditions</strong>
            </p>
          </div>
          <div
              className="tab-pane fade px-5 mx-5 pb-5"
              id="ex3-tabs-8"
              role="tabpanel"
              aria-labelledby="ex3-tab-3"
          >
            <a href={interestRatePolicy} target="_blank"
               rel="noreferrer">
              <p className="text-center text-decoration-underline fs-2 mb-20"> Download Interest Rate Policy</p>
            </a>
          </div>
          <div
              className="tab-pane fade px-5 mx-5 pb-5"
              id="ex3-tabs-10"
              role="tabpanel"
              aria-labelledby="ex3-tab-10"
          >
            <a href={refundPolicy} target="_blank"
               rel="noreferrer">
              <p className="text-center text-decoration-underline fs-2 mb-20"> Download Refund Policy</p>
            </a>
          </div>
          <div
              className="tab-pane fade px-5 mx-5 pb-5"
              id="ex3-tabs-9"
              role="tabpanel"
              aria-labelledby="ex3-tab-3"
          >
            <a href={codeOfConductPolicy} target="_blank"
               rel="noreferrer">
              <p className="text-center text-decoration-underline fs-2 mb-20">Download Code Of Conduct Policy</p>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Policies
