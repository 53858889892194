// import React from "react";
// import logo from "../images/logo.png";
// import {Link} from 'react-router-dom'

// const Navbar = () => {
//   return (
//     <>
//       <nav class="navbar navbar-expand-lg navbar-light bg-light ">
//         <Link class="navbar-brand" to="/" style={{ paddingLeft: "7%" }}>
//           <img src={logo} />
//         </Link>
//         <button
//           class="navbar-toggler"
//           type="button"
//           data-toggle="collapse"
//           data-target="#navbarNavDropdown"
//           aria-controls="navbarNavDropdown"
//           aria-expanded="true"
//           aria-label="Toggle navigation"
//         >
//           <span class="navbar-toggler-icon"></span>
//         </button>
//         <div
//           class="collapse navbar-collapse"
//           id="navbarNavDropdown"
//           style={{ justifyContent: "end",marginRight:"10em" }}
//         >
//           <ul class="navbar-nav">
//             <li class="nav-item">
//               <Link class="nav-link" to="/">
//                 Home
//               </Link>
//             </li>
//             <li class="nav-item">
//               <Link class="nav-link" to="/products">
//                 Products
//               </Link>
//             </li>
//             <li class="nav-item">
//               <Link class="nav-link" to="/who-we-are">
//                 Who We Are
//               </Link>
//             </li>
            
//             <li class="nav-item">
//               <Link class="nav-link" to="/policies">
//                 Policies
//               </Link>
//             </li>{" "}
//             <li class="nav-item">
//               <Link class="nav-link" to="/contact-us">
//                 Contact Us
//               </Link>
//             </li>
//           </ul>
//         </div>
//       </nav>
//     </>
//   );
// };

// export default Navbar;


// import React from "react";
// import logo from "../images/logo.png";
// import { Link, NavLink } from "react-router-dom";

// const Navbar = () => {
//   return (
//     <>
//       <nav className="navbar navbar-expand-lg navbar-light bg-light ft-bold shadow-sm fixed-top">
//         <div className="container">
//           <Link className="navbar-brand" to="/" style={{ paddingLeft: "7%" }}>
//              <img src={logo} alt="Logo" />
//             {" "}
//           </Link>
//           <button
//             className="navbar-toggler"
//             type="button"
//             data-bs-toggle="collapse"
//             data-bs-target="#navbarNavDropdown"
//             aria-controls="navbarNavDropdown"
//             aria-expanded="false"
//             aria-label="Toggle navigation"
//           >
//             <span className="navbar-toggler-icon"></span>
//           </button>
//           <div className="collapse navbar-collapse" id="navbarNavDropdown">
//             <ul className="navbar-nav ms-auto">
//               <li className="nav-item">
//                 <NavLink
//                   className="nav-link mx-3"
//                   to="/"
//                   activeClassName="active"
//                   exact
//                 >
//                   Home
//                 </NavLink>
//               </li>
//               <li className="nav-item mx-3">
//                 <NavLink
//                   className="nav-link"
//                   to="/products"
//                   activeClassName="active"
//                 >
//                   Products
//                 </NavLink>
//               </li>
//               {/* <li className="nav-item">
//                 <NavLink
//                   className="nav-link"
//                   to="/who-we-are"
//                   activeClassName="active"
//                 >
//                   Who We Are
//                 </NavLink>
//               </li> */}
//               <li className="nav-item mx-3">
//                 <NavLink
//                   className="nav-link"
//                   to="/policies"
//                   activeClassName="active"
//                 >
//                   Policies
//                 </NavLink>
//               </li>
//               <li className="nav-item mx-3">
//                 <NavLink
//                   className="nav-link"
//                   to="/contact-us"
//                   activeClassName="active"
//                 >
//                   Contact Us
//                 </NavLink>
//               </li>
//             </ul>
//           </div>
//         </div>
//       </nav>
//     </>
//   );
// };

// export default Navbar;



import React from "react";
import logo from "../images/logo.png";
import { Link, NavLink } from "react-router-dom";

const Navbar = () => {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light ft-bold shadow-sm fixed-top">
        <Link className="navbar-brand" to="/" style={{ paddingLeft: "7%" }}>
          <img src={logo} alt="Logo" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
          style={{ marginRight: "26px" }}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse"
          id="navbarNavDropdown"
          style={{ justifyContent: "end", marginRight: "10em" }}
        >
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink
                className="nav-link mx-3"
                to="/"
                activeClassName="active"
                exact
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item mx-3">
              <NavLink
                className="nav-link "
                to="/products"
                activeClassName="active"
              >
                Products
              </NavLink>
            </li>
            {/* <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/who-we-are"
                activeClassName="active"
              >
                Who We Are
              </NavLink>
            </li> */}
            <li className="nav-item mx-3">
              <NavLink
                className="nav-link"
                to="/policies"
                activeClassName="active"
              >
                Policies
              </NavLink>
            </li>{" "}
            <li className="nav-item mx-3">
              <NavLink
                className="nav-link"
                to="/contact-us"
                activeClassName="active"
              >
                Contact Us
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
