import React from 'react'
import Aprajita from "../images/Aprajita.png";
import Rohan_palaha from "../images/Rohan_palaha.png";
import Gaurav_wadhva from "../images/Gaurav_wadhva.png";
import Rahul_jain from "../images/Rahul_jain.png";

const Who_we_are = () => {
  return (
    <>
      <section className="who-we-are-header mb-4">
        <div className="row ">
          <p className="about-line"></p>
          <p className="about-heading">Meet Our Board of Members</p>
        </div>
      </section>
      <section className="members-data mx-4 px-5">
        <div className="row my-4">
          <div className="col-4 px-3">
            <img
              src={Aprajita}
              alt="Aprajita Saigal"
              className="rounded-circle"
            />
          </div>
          <div className="col-8">
            <p className="member-name">Aprajita Saigal</p>
            <p className="member-degination">
              Chief Executive Officer-NBFC (CEO-NBFC)
            </p>
            <p className="description">
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters, as opposed to using 'Content here,
              content here', making it look like readable English. Many desktop
              publishing packages and web page editors now use Lorem Ipsum as
              their default model text, and a search for 'lorem ipsum' will
              uncover many web sites still in their infancy. Various versions
              have evolved over the years, sometimes by accident, sometimes on
              purpose (injected humour and the like)
            </p>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-8">
            <p className="member-name">Rohan Palaha</p>
            <p className="member-degination">Chief Executive Officer (CEO)</p>
            <p className="description">
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters, as opposed to using 'Content here,
              content here', making it look like readable English. Many desktop
              publishing packages and web page editors now use Lorem Ipsum as
              their default model text, and a search for 'lorem ipsum' will
              uncover many web sites still in their infancy. Various versions
              have evolved over the years, sometimes by accident, sometimes on
              purpose (injected humour and the like)
            </p>
          </div>
          <div className="col-4 px-3">
            <img
              src={Rohan_palaha}
              alt="Rohan Palaha"
              className="rounded-circle"
            />
          </div>
        </div>
        <div className="row my-4">
          <div className="col-4 px-3">
            <img
              src={Gaurav_wadhva}
              alt="Gaurav Wadhva"
              className="rounded-circle"
            />
          </div>
          <div className="col-8">
            <p className="member-name">Gaurav Wadhwa</p>
            <p className="member-degination">Chief Business Officer (CBO)</p>
            <p className="description">
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters, as opposed to using 'Content here,
              content here', making it look like readable English. Many desktop
              publishing packages and web page editors now use Lorem Ipsum as
              their default model text, and a search for 'lorem ipsum' will
              uncover many web sites still in their infancy. Various versions
              have evolved over the years, sometimes by accident, sometimes on
              purpose (injected humour and the like)
            </p>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-8">
            <p className="member-name">Rahul Jain</p>
            <p className="member-degination">Chief Technology Officer (CTO)</p>
            <p className="description">
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters, as opposed to using 'Content here,
              content here', making it look like readable English. Many desktop
              publishing packages and web page editors now use Lorem Ipsum as
              their default model text, and a search for 'lorem ipsum' will
              uncover many web sites still in their infancy. Various versions
              have evolved over the years, sometimes by accident, sometimes on
              purpose (injected humour and the like)
            </p>
          </div>
          <div className="col-4 px-3">
            <img
              src={Rahul_jain}
              alt="Rohan Palaha"
              className="rounded-circle"
            />
          </div>
        </div>
      </section>
      <section className=" mt-5">
        <div className="row management-team-section mb-5">
          <p className="about-line"> </p>
          <p className="about-heading">Meet Our management Team</p>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-6 mt-60">
              <div className="meet-box">
                <div className="gs-icon text-center">
                  <img
                    style={{ borderRadius: "180px", maxWidth: "50%" }}
                    src={Rohan_palaha}
                    alt="..."
                  />
                </div>
                <div className="xs-title text-center mt-4">
                  <a
                    href="#"
                    target="_target"
                    style={{ textDecoration: "none" }}
                  >
                    {`Full Name`}
                  </a>
                </div>
                <div className="sub-title text-center green-text mt-10">
                  {`degination`}
                </div>
              </div>
            </div>
            <div className="col-md-3 col-6 mt-60">
              <div className="meet-box">
                <div className="gs-icon text-center">
                  <img
                    style={{ borderRadius: "180px", maxWidth: "50%" }}
                    src={Rohan_palaha}
                    alt="..."
                  />
                </div>
                <div className="xs-title text-center mt-4">
                  <a
                    href="#"
                    target="_target"
                    style={{ textDecoration: "none" }}
                  >
                    {`Full Name`}
                  </a>
                </div>
                <div className="sub-title text-center green-text mt-10">
                  {`degination`}
                </div>
              </div>
            </div>
            <div className="col-md-3 col-6 mt-60">
              <div className="meet-box">
                <div className="gs-icon text-center">
                  <img
                    style={{ borderRadius: "180px", maxWidth: "50%" }}
                    src={Rohan_palaha}
                    alt="..."
                  />
                </div>
                <div className="xs-title text-center mt-4">
                  <a
                    href="#"
                    target="_target"
                    style={{ textDecoration: "none" }}
                  >
                    {`Full Name`}
                  </a>
                </div>
                <div className="sub-title text-center green-text mt-10">
                  {`degination`}
                </div>
              </div>
            </div>
            <div className="col-md-3 col-6 mt-60">
              <div className="meet-box">
                <div className="gs-icon text-center">
                  <img
                    style={{ borderRadius: "180px", maxWidth: "50%" }}
                    src={Rohan_palaha}
                    alt="..."
                  />
                </div>
                <div className="xs-title text-center mt-4">
                  <a
                    href="#"
                    target="_target"
                    style={{ textDecoration: "none" }}
                  >
                    {`Full Name`}
                  </a>
                </div>
                <div className="sub-title text-center green-text mt-10">
                  {`degination`}
                </div>
              </div>
            </div>
            <div className="col-md-3 col-6 mt-5">
              <div className="meet-box">
                <div className="gs-icon text-center">
                  <img
                    style={{ borderRadius: "180px", maxWidth: "50%" }}
                    src={Rohan_palaha}
                    alt="..."
                  />
                </div>
                <div className="xs-title text-center mt-4">
                  <a
                    href="#"
                    target="_target"
                    style={{ textDecoration: "none" }}
                  >
                    {`Full Name`}
                  </a>
                </div>
                <div className="sub-title text-center green-text mt-10">
                  {`degination`}
                </div>
              </div>
            </div>
            <div className="col-md-3 col-6 mt-5">
              <div className="meet-box">
                <div className="gs-icon text-center">
                  <img
                    style={{ borderRadius: "180px", maxWidth: "50%" }}
                    src={Rohan_palaha}
                    alt="..."
                  />
                </div>
                <div className="xs-title text-center mt-4">
                  <a
                    href="#"
                    target="_target"
                    style={{ textDecoration: "none" }}
                  >
                    {`Full Name`}
                  </a>
                </div>
                <div className="sub-title text-center green-text mt-10">
                  {`degination`}
                </div>
              </div>
            </div>
            <div className="col-md-3 col-6 mt-5">
              <div className="meet-box">
                <div className="gs-icon text-center">
                  <img
                    style={{ borderRadius: "180px", maxWidth: "50%" }}
                    src={Rohan_palaha}
                    alt="..."
                  />
                </div>
                <div className="xs-title text-center mt-4">
                  <a
                    href="#"
                    target="_target"
                    style={{ textDecoration: "none" }}
                  >
                    {`Full Name`}
                  </a>
                </div>
                <div className="sub-title text-center green-text mt-10">
                  {`degination`}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Who_we_are