import React from 'react'
import {Link} from 'react-router-dom'
import groupimg from '../images/Group 40962.png'
import Group_40897 from "../images/Group_40897.png";
import Group_40870 from "../images/Group40870.png";
import Frame_406933 from "../images/Frame_406933.png";
import Group_41060 from "../images/Group_41060.png";
import Group_41062 from "../images/Group 41062.png";
import Group_41063 from "../images/Group 41063.png";
import Group_41064 from "../images/Group 41064.png";
import Group_41065 from "../images/Group 41065.png";
import finsara_logo from "../images/finsara_logo.png";
import finsara_Img from "../images/finsara_Img.png";




const Homepage = () => (
  <>
    <div
      className="container-fluid pt-5 mt-5 mb-5"
      style={{ paddingLeft: "7%" }}
    >
      <div className="row headcontainer">
        <div className="col-md-5 pr-4 col-sm-12">
          <p className="heading">
            Personal & Business Loans to meet all your needs!
          </p>
          <p className="homepage-knowmore-btn">
            {" "}
            Rinkpi Finance & Consultants Private Limited <br />
            is an RBI registered Non-Banking Financial Company.
          </p>
          <Link to="/products" className="knwbtn text-decoration-none">
            Know More{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="26"
              fill="currentColor"
              class="bi bi-arrow-right-short"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
            </svg>
          </Link>
        </div>
        <div
          className="col-md-6 col-sm-12"
          style={{ marginLeft: "auto", marginRight: "auto" }}
        >
          <img src={groupimg} alt="..." className="" />
        </div>
      </div>
    </div>

    <div className="homepage-about-section">
      <div className="row">
        <p className="about-heading">About Us</p>
      </div>
      <div className="row">
        <p
          className="about-content text-align-justify"
          style={{ color: "#FFFFFF"}}
        >
          Rinkpi Finance & Consultants Private Limited is an RBI registered
          Non-Banking Financial Company with over 2 decades of experience in
          responsible financial services. It is our endeavor to find hidden
          primes in new to credit landscape. India is going through a paradigm
          shift as it consumes credit and is seen in the borrower profile.
          With this rapid evolution in India’s financial ecosystem, lending
          decisions are now powered by data across parameters and dimensions.
          We are at the front of this evolution helping our borrowers get
          access to credit using technology automation, machine-learning
          powered scorecards and analytical models.
        </p>
      </div>
    </div>
    {/* <div className="our-mission">
          <div className="row">
            <div className="col our-mission-content">
              <p className="our-mission-heading">Our Mission</p>
              <p className="fs-5" style={{ color: "#414141" }}>
                Our mission is to be the most preferred NBFC for Financial
                inclusion by developing underwriting technology and processes for
                unserved population.
              </p>
            </div>
            <div className="col">
              <img
                src={Group_40897}
                alt="..."
                className="left-aligned-image"
                style={{ marginTop: "50px" }}
              />
            </div>
          </div>
        </div> */}
    <div className="our-mission">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 px-3">
            <div className="our-mission-content">
              <p
                className="our-mission-heading"
                style={{ textAlign: "left" }}
              >
                Our Mission
              </p>
              <p className="fs-5" style={{ color: "#414141" }}>
                Our mission is to be the most preferred NBFC for Financial
                inclusion by developing underwriting technology and processes
                for the unserved population.
              </p>
            </div>
          </div>
          <div
            className="col-md-6 col-sm-12 "
            style={{ paddingLeft: "10%" }}
          >
            <img src={Group_40897} alt="..." className="" />
          </div>
          {/* <div
          className="col-md-6"
          style={{ marginLeft: "auto", marginRight: "auto" }}
        >
          <img
            src={Group_40897}
            alt="..."
            className="" // Use img-fluid to ensure the image is responsive
            style={{ marginTop: "50px" }}
          />
        </div> */}
        </div>
      </div>
    </div>

    <div className="our-mission">
      <div className="container-fluid" >
        <div className="row">
          <div className="col">
            <img
              src={Group_40870}
              alt="..."
              className="left-aligned-image"
              style={{ marginTop: "50px" }} />
          </div>
          <div className="col our-mission-content text-center">
            <p className="our-mission-heading our-vision-heading" style={{ textAlign: "center" }}>Our Vision</p>
            <p className="fs-5" style={{ maxWidth: "80%", color: "#414141" }}>
              Our vision is to reinvent the credit wheel to cause a meaningful
              difference to the lives of unserved and under-served by helping
              them get access to formal credit at reasonable rate of interest.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="testimonials mt-5">
      <p className="centered-paragraph-corevalue">Core values</p>
      <div className="row justify-content-center px-1">
        <div className="col-10 col-md-5 col-lg-3 col-xl-2 mb-4">
          <img src={Group_41060} alt="Icon" className="icon-image" />
          <h5 class="card-title text-center">
            Customer <br /> first
          </h5>
        </div>
        <div className="col-10 col-md-5 col-lg-3 col-xl-2 mb-4">
          <img src={Group_41064} alt="Icon" className="icon-image" />
          <h5 class="card-title text-center">
            In the business <br /> of building trust
          </h5>
        </div>

        <div className="col-10 col-md-5 col-lg-3 col-xl-2 mb-3">
          <img src={Group_41062} alt="Icon" className="icon-image" />
          <h5 class="card-title text-center">
            Change <br />
            agents
          </h5>
        </div>
        <div className="col-10 col-md-5 col-lg-3 col-xl-2 mb-3">
          <img src={Group_41065} alt="Icon" className="icon-image" />
          <h5 class="card-title text-center">
            We own <br />
            our actions
          </h5>
        </div>
        <div className="col-10 col-md-5 col-lg-3 col-xl-2 mb-3">
          <img src={Group_41063} alt="Icon" className="icon-image" />
          <h5 class="card-title text-center">Here to make a difference</h5>
        </div>
      </div>
    </div>
    <div className="work_with_best_practice mt-5">
      <p className="centered-paragraph-corevalue">Our Partnerships</p>
      <div className="row">
        <div className="col-md-6 col-sm-12">
          {/* <p className="our-partnership-heading text-start">
          We have partnered with
        </p> */}
          <img src={finsara_logo} alt="finsara logo" className="my-3" />
          <p className="finsara-text text-start">
            For building inclusive, and risk-averse credit products for Bharat
            like Credit Limit and Personal Loans.
          </p>
          <button className="knwbtn">
            <a
              href="https://finsara.app/"
              target="_blank"
              className="text-decoration-none text-white"
            >
              More Information{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                fill="currentColor"
                class="bi bi-arrow-right-short"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
              </svg>
            </a>
          </button>
        </div>
        <div className="col-md-6 col-sm-12">
          <img
            src={finsara_Img}
            alt="..."
            className=""
            style={{ marginTop: "50px", width: "80%", height: "auto" }} />
        </div>
      </div>
    </div>
  </>
)

export default Homepage




{/* <div className="testimonials mt-5">
        <p className="centered-paragraph">Testimonials</p>
        <p className="testimonials-heading">Read What Our Customer Say</p>
        <div className="row justify-content-center">
          {" "}
          {/* Use justify-content-center */}
        //   <div className="col-md-3 ml-2">
        //     <div class="card shadow" style={{ width: "auto" }}>
        //       <div class="card-body">
        //         <p class="card-text">
        //           finsara has quickly become one of my favorite app. I
        //           appreciate the attention to detail and dedication to user
        //           satisfaction that the developers have put into finsara. It’s
        //           truly a game-changer and a must-have app for anyone who
        //           looking for credit limit!
        //         </p>
        //         <h5 class="card-title text-start">Miss. Jyoti Yadav</h5>
        //       </div>
        //     </div>
        //   </div>
        //   <div className="col-md-3">
        //     <div className="card shadow" style={{ width: "auto" }}>
        //       <div class="card-body">
        //         <p class="card-text text-start">
        //           An exceptional app that seamlessly blends user-friendly design
        //           with a wide range of features. The intuitive interface makes
        //           navigation a breeze, while the robust functionality ensures
        //           all my needs are met. This app has truly transformed the way I
        //           manage my finance issues. Highly recommended!
        //         </p>
        //         <h5 class="card-title text-start">Mr. Pawan Kumar</h5>
        //       </div>
        //     </div>
        //   </div>
        //   <div className="col-md-3">
        //     <div class="card shadow" style={{ width: "auto" }}>
        //       <div class="card-body">
        //         <p class="card-text">
        //           Finsara you are very nice, it is useful in bad times, that's
        //           why all the people of my house do it and its charge is also
        //           very less!
        //         </p>
        //         <h5 class="card-title text-start">Mrs. Annu Devi</h5>
        //       </div>
        //     </div>
        //   </div>
        // </div>
