import React from 'react'
import icon1 from '../images/icon1.png'
import icon2 from "../images/icon2.png";
import icon3 from "../images/icon3.png";
import icon4 from "../images/icon4.png";
import launch_button from "../images/launch.png";
import icon5 from "../images/timelapse.png";
import icon6 from "../images/upload-alt.svg";
import icon7 from "../images/work_off.png";
import icon8 from "../images/icon4.svg";
import icon9 from "../images/replay_30.png";

const Product = () => {
  return (
    <>
      <section className="product-page-header my-5">
        <div className="row ">
          <p className="about-heading">Our Products and Services</p>
        </div>
      </section>
      <section className="product-page-section mb-5">
        <p className="p-heading">Our Products</p>
        <p className="text-center">
          Helping transform your day to day lifes with our customized credit
          solutions
        </p>
        <div className="row justify-content-center px-5 mx-4">
          {" "}
          <div className="col-10 col-md-6 col-lg-4 col-xl-3 mb-4">
            <div class="card shadow" style={{ width: "auto", height: "100%" }}>
              <div class="card-body">
                <img src={icon1} alt="Icon" className="icon-image mb-4" />
                <h5 class="card-title text-center">Credit Limit</h5>
                <p class="card-text">
                  A Credit Line/Limit is a flexible loan with a predetermined
                  credit limit that you borrow and repay per your convenience.
                  Our line of credit product supports multiple withdrawals up to
                  the sanctioned limit instantly credited to your bank account.
                </p>
                
              </div>
              <img src={launch_button} alt="Icon" className="icon-image mb-5" />
              
            </div>
          </div>
          <div className="col-10 col-md-6 col-lg-4 col-xl-3 mb-4">
            <div class="card shadow" style={{ width: "auto" }}>
              <div class="card-body">
                <img src={icon2} alt="Icon" className="icon-image mb-4" />
                <h5 class="card-title text-center">Personal Loan</h5>
                <p class="card-text">
                  Personal Loans are offered to help meet your financial
                  obligations and expenses. Whether it's for a family wedding,
                  higher education, medical emergencies, travel and vacation or
                  to renovate your home, our Personal Loans offer convenient and
                  speedy resolutions to your needs.
                </p>
              </div>
              <img src={launch_button} alt="Icon" className="icon-image mb-4" />
            </div>
          </div>
          <div className="col-10 col-md-6 col-lg-4 col-xl-3 mb-4">
            <div class="card shadow" style={{ width: "auto" }}>
              <div class="card-body">
                <img src={icon3} alt="Icon" className="icon-image mb-4" />
                <h5 class="card-title text-center">MSME Business Loan</h5>
                <p class="card-text">
                  With your business plan/growth stage, lack of finances is the
                  last thing that should stand between you and your business
                  business. We offer Business Loans for working capital needs,
                  business expansion, technology investments and bridge
                  financing requirements.
                </p>
              </div>
              <img src={launch_button} alt="Icon" className="icon-image mb-4" />
            </div>
          </div>
          <div className="col-10 col-md-6 col-lg-4 col-xl-3 mb-4">
            <div class="card shadow" style={{ width: "auto" }}>
              <div class="card-body">
                <img src={icon4} alt="Icon" className="icon-image mb-4" />
                <h5 class="card-title text-center">Education Loan</h5>
                <p class="card-text">
                  Let your dreams take wings with our education loan. Whatever
                  be your financial situation or status of admission, don't
                  compromise your goals. Take a decision to a better future.
                  Meet your specific education loans need with us – The most
                  flexible, friendly, customisable.
                </p>
              </div>
              <img src={launch_button} alt="Icon" className="icon-image mb-4" />
            </div>
          </div>
        </div>
      </section>
      <section className="why-finsara-section mb-4">
        <p className="p-heading">Why choose us?</p>
        <p className="text-center">
          FINSARA provides new-age customized loans for you & your business
          needs.
        </p>
        <div className="row justify-content-center px-1">
          <div className="col-10 col-md-5 col-lg-3 col-xl-2 mb-4">
            <div class="card shadow" style={{ width: "auto" }}>
              <div class="card-body">
                <img src={icon5} alt="Icon" className="icon-image mb-4" />
                <h5 class="card-title text-center">
                  Flexible repayment tenure
                </h5>
              </div>
            </div>
          </div>
          <div className="col-10 col-md-5 col-lg-3 col-xl-2 mb-4">
            <div class="card shadow" style={{ width: "auto" }}>
              <div class="card-body">
                <img src={icon6} alt="Icon" className="icon-image mb-4" />
                <h5 class="card-title text-center">Minimum Interest Rate</h5>
              </div>
            </div>
          </div>

          <div className="col-10 col-md-5 col-lg-3 col-xl-2 mb-4">
            <div class="card shadow" style={{ width: "auto" }}>
              <div class="card-body">
                <img src={icon7} alt="Icon" className="icon-image mb-4" />
                <h5 class="card-title text-center">Minimum Paper Work</h5>
              </div>
            </div>
          </div>
          <div className="col-10 col-md-5 col-lg-3 col-xl-2 mb-4">
            <div class="card shadow" style={{ width: "auto" }}>
              <div class="card-body">
                <img src={icon8} alt="Icon" className="icon-image mb-4" />
                <h5 class="card-title text-center">
                  Easy EMI <br />
                  Options
                </h5>
              </div>
            </div>
          </div>
          <div className="col-5 col-md-4 col-lg-3 col-xl-2 mb-4">
            <div class="card shadow" style={{ width: "auto" }}>
              <div class="card-body">
                <img src={icon9} alt="Icon" className="icon-image mb-4" />
                <h5 class="card-title text-center">
                  Approval Within 30 Minutes
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Product




////////////////////////////////FAQs Section/////////////////////////
{/* <section className="faq-section mt-4">
        <p className="faq-heading">Frequently Asked Questions</p>
        <div class="accordion" id="accordionExample">
          <div class="accordion-item">
            <h2 class="accordion-header">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                What is Finsara?
              </button>
            </h2>
            <div
              id="collapseOne"
              class="accordion-collapse collapse show"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                Working capital is a term used to describe a company's or
                organization's ability to finance its day-to-day operations. It
                can be thought of as a scale for evaluating a company's overall
                efficiency. To calculate a firm's or organization's working
                capital, subtract the current liabilities from the total current
                assets. Positive working capital indicates that a company's
                assets are sufficient to cover its short-term liabilities.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                Where is FINSARA available?
              </button>
            </h2>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                FINSARA is available in 25+ cities - Delhi, Mumbai, Pune,
                Ahmedabad, Surat, Rajkot, Nagpur, Ghaziabad, Noida, Vadodara,
                Indore, Ujjain, Thane, Bhopal, Ludhiana, Jamshedpur, Nasik,
                Aurangabad, Rajkot, Jabalpur, Vasai, Ranchi, Gwalior,
                Chandigarh, Jalandhar, Agra, Faridabad, Karnal, Panipat,
                Gurgaon".
                <br />
                <strong>
                  Very soon we’re expanding our service to pan India.
                </strong>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                How to get started on FINSARA?
              </button>
            </h2>
            <div
              id="collapseThree"
              class="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <ol>
                  <li>
                    Download the App (Please note: we are operating in limited
                    cities in India)
                  </li>
                  <li>
                    Sign in using your Aadhar registered phone number only
                  </li>
                  <li>Complete your KYC with your Aadhaar and PAN</li>
                  <li>Provide Income or Employment Proof</li>
                  <li>Video Selfie</li>
                  <li>
                    Get instant credit limit approved of up to INR 10,000
                    (remember to look at the sanction letter and loan agreement
                  </li>
                  <li>
                    You can transfer money to your bank account at time (in some
                    cases you will be required to scan merchant UPI QR code to
                    get money in your bank account)
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                Who are the lending partners?
              </button>
            </h2>
            <div
              id="collapseFour"
              class="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <ul>
                  <li>Ganesh Ganga Investment Private Limited</li>
                  <li>Rinpki Finance Investment Private Limited</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section> */}