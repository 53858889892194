import React from 'react'
import {Link} from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div>
        <footer
          class="text-center text-lg-start"
          style={{ color: "#737373", backgroundColor: "#F6F6F6" }}
        >
          <section class="">
            <div class="container-fluid text-center text-md-start mt-5">
              <div class="row mb-4 " style={{display: 'flex',justifyContent: 'space-between'}}  >
                <div class="col-md-5 col-lg-4 col-xl-3 mx-auto mb-4">
                  <h6 class="text-uppercase fw-bold mt-5">Get In Touch</h6>
                  <hr
                    class="mb-4 mt-0 d-inline-block mx-auto"
                    style={{
                      width: "60px;",
                      color: "#7c4dff;",
                      height: "2px",
                    }}
                  />
                  <p>
                    CIN: U74140DL1993PTC390764 <br />
                    RBI Registration No. : B-05.04937<br/>
                    GST -  07AABCR6133K1ZB
                    <br />
                    <br />
                    Regd. Office: Flat No.1208 D, Vijaya Building,
                    <br /> Barakhamba Road Connaught Place New Delhi Central
                    Delhi DL 110001 IN
                    <br />
                    <br />
                    Email: info@rinkpifinance.com
                    <br /> Contact: 0120-5138158
                  </p>
                </div>
                <div
                  class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4"
                  style={{ color: "#737373" }}
                >
                  <h6 class="text-uppercase fw-bold">Company Info</h6>
                  <hr
                    class="mb-4 mt-0 d-inline-block mx-auto"
                    style={{
                      width: "60px;",
                      height: "2px",
                    }}
                  />
                  <p><Link to="/">Home</Link></p>
                  <p><Link to="/products">Products</Link></p>
                  <p><Link to="/policies">Policies</Link></p>
                  <p><Link to="/contact-us">Contact us</Link></p>
                </div>
                {/*<div*/}
                {/*  class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4"*/}
                {/*  style={{ color: "#737373" }}*/}
                {/*>*/}
                {/*  <h6 class="text-uppercase fw-bold">Products</h6>*/}
                {/*  <hr*/}
                {/*    class="mb-4 mt-0 d-inline-block mx-auto"*/}
                {/*    style={{*/}
                {/*      width: "60px",*/}
                {/*      height: "2px",*/}
                {/*    }}*/}
                {/*  />*/}
                {/*  <p>Credit Limit/Line</p>*/}
                {/*  <p>Personal Loan</p>*/}
                {/*  <p>MSME Business Loan</p>*/}
                {/*  <p>Education loan</p>*/}
                {/*</div>*/}
                {/*<div*/}
                {/*  class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4"*/}
                {/*  style={{ color: "#737373" }}*/}
                {/*>*/}
                {/*  <h6 class="text-uppercase fw-bold">Resources</h6>*/}
                {/*  <hr*/}
                {/*    class="mb-4 mt-0 d-inline-block mx-auto"*/}
                {/*    style={{*/}
                {/*      width: "60px",*/}
                {/*      height: "2px",*/}
                {/*    }}*/}
                {/*  />*/}
                {/*  <p>KYC and AML</p>*/}
                {/*  <p>Fair Practice Code</p>*/}
                {/*  <p>Grievances Redressal Code</p>*/}
                {/*  <p>Privacy Policy</p>*/}
                {/*</div>*/}
              </div>
            </div>
          </section>
          <div
            className="text-center p-3"
            style={{ color: "#737373", backgroundColor: "#FAFAFA" }}
          >
            Made With{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 512 512"
              style={{ fill: "#e70d23" }}
            >
              <path d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z" />
            </svg>{" "}
            By Rinkpi Finance and All Right Reserved
          </div>{" "}
        </footer>
      </div>
    </>
  );
}

export default Footer