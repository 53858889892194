// import React,{useState} from 'react'
// import Group_40907 from "../images/Group 40907.png";

// const Contact = () => {

//     const [mobileNumber, setMobileNumber] = useState("");

//     const handleMobileNumberChange = (e) => {
//       const inputValue = e.target.value;
//       const mobileNumberRegex = /^\d{0,10}$/;
//       if (mobileNumberRegex.test(inputValue)) {
//         setMobileNumber(inputValue);
//       }
//     };
//   return (
//     <>
//       <section className="contact-us-section">
//         <div className="container py-5">
//           <div className="row">
//             <div className="col-md-5 col-12 text-center">
//               <img src={Group_40907} alt="..." />
//             </div>
//             <div className="col-md col-12 text-center">
//               <p className="contact-heading">We’d love to help</p>
//               <p>Reach out and we’ll get in touch with in 24 hours.</p>
//               <div class="row g-2">
//                 <div class="col-md-6">
//                   <div class="form-floating">
//                     <input
//                       type="text"
//                       class="form-control"
//                       id="floatingInputGrid"
//                       required="true"
//                       placeholder="Your Name"
//                     />
//                     <label for="floatingInputGrid">Your Name</label>
//                   </div>
//                 </div>
//                 <div class="col-md-6">
//                   <div class="form-floating">
//                     <input
//                       type="email"
//                       class="form-control"
//                       id="floatingInputGrid"
//                       placeholder="Your Email"
//                     />
//                     <label for="floatingInputGrid">Your Email</label>
//                   </div>
//                 </div>
//                 <div class="col-md-6">
//                   <div class="form-floating">
//                     <input
//                       type="tel"
//                       class="form-control"
//                       id="floatingInputGrid"
//                       placeholder="Your Mobile Number"
//                       value={mobileNumber}
//                       onChange={handleMobileNumberChange}
//                     />
//                     <label for="floatingInputGrid">Your Mobile Number</label>
//                   </div>
//                 </div>
//                 <div class="col-md-6">
//                   <div class="form-floating">
//                     <select class="form-select" id="floatingSelectGrid">
//                       <option selected>Select Subject</option>
//                       <option value="math">Credit Limit</option>
//                       <option value="science">Home Loan</option>
//                       <option value="history">Business Loan</option>
//                       <option value="history">Complaint</option>
//                     </select>
//                     <label for="floatingSelectGrid">Subject</label>
//                   </div>
//                 </div>

//                 <div className="col-md-12">
//                   <textarea
//                     class="form-control"
//                     id="exampleFormControlTextarea1"
//                     rows="3"
//                     placeholder="Describe Your Query"
//                   ></textarea>
//                 </div>
//                 <div className="col-md-12">
//                   <div class="form-check">
//                     <input
//                       class="form-check-input"
//                       type="checkbox"
//                       value=""
//                       id="flexCheckDefault"
//                     />
//                     <label class="form-check-label" for="flexCheckDefault">
//                       <p className="agreement" style={{ color: "#414141" }}>
//                         You agree to our friendly{" "}
//                         <span style={{ color: "#33AB51" }}>
//                           privacy policy.
//                         </span>
//                       </p>
//                     </label>
//                   </div>
//                 </div>
//               </div>
//               <button className="knwbtn">
//                 Submit{" "}
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   width="26"
//                   height="26"
//                   fill="currentColor"
//                   class="bi bi-arrow-right-short"
//                   viewBox="0 0 16 16"
//                 >
//                   <path
//                     fill-rule="evenodd"
//                     d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
//                   />
//                 </svg>
//               </button>
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// }

// export default Contact



import React, {useRef, useState } from "react";
import emailjs from "emailjs-com";
import Group_40907 from "../images/Group 40907.png";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import { styled } from "@mui/system";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { FormHelperText } from "@mui/material";



const Contact = () => {
  const form = useRef();

  // const [formData, setFormData] = useState({
  //   name: "",
  //   reply_to: "",
  //   mobileNumber: "",
  //   subject: "Select Subject",
  //   issueType: "",
  //   agreeToPrivacyPolicy: false,
  // });

  // const handleInputChange = (e) => {
  //   const { name, value, type, checked } = e.target;
  //   setFormData({
  //     ...formData,
  //     [name]: type === "checkbox" ? checked : value,
  //   });
  // };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   for (const key in formData) {
  //     if (!formData[key]) {
  //       alert("Please fill in all fields.");
  //       return;
  //     }
  //   }

  //   emailjs
  //     .send(
  //       "service_qmia7io",
  //       "template_q3z10n6",
  //       formData,
  //       "MAKv9fZEpcfcMducI"
  //     )
  //     .then(
  //       (response) => {
  //         console.log("Email sent successfully:", response);

  //       },
  //       (error) => {
  //         console.error("Email sending failed:", error);

  //       }
  //     );
  // };


  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [issueType, setIssueType] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let formIsValid = true;
    const newErrors = {};

    if (!name.trim()) {
      newErrors.name = "Enter your name";
      formIsValid = false;
    } else if (/\d/.test(name)) {
      newErrors.name = "Name should not contain numbers";
      formIsValid = false;
    } else if (/^\s/.test(name)) {
      newErrors.name = "Name should not start with whitespace";
      formIsValid = false;
    }

    if (!phoneNumber.trim()) {
      newErrors.phoneNumber = "Enter your phone number";
      formIsValid = false;
      if (phoneNumber === "phoneNumber") {
        if (/^[6-9]\d{0,9}$/.test(phoneNumber) || phoneNumber === "") {
          return;
        }
      }
    }

    if (!issueType) {
      newErrors.issueType = "Select Product Type";
      formIsValid = false;
    }

    if (!message) {
      newErrors.message = "Write something about your query";
    }
    if (!email) {
      newErrors.email = "Enter e-mail address";
      formIsValid = false;
    }

    setErrors(newErrors);
    return formIsValid;
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      const templateParams = {
        Name: name,
        email: email,
        phoneNumber: phoneNumber,
        issueType: issueType,
        message: message,
      };
      emailjs
        .sendForm(
          "service_qmia7io",
          "template_q3z10n6",
          form.current,
          "MAKv9fZEpcfcMducI",
          templateParams
        )
        .then(
          (result) => {
            // Handle success
            console.log(result.text);
            toast.success(
              "Your request has been submitted successfully and we'll get back to you within 24-48 hrs",
              {
                position: toast.POSITION.TOP_CENTER,
              }
            );

            // Clear form fields
            setName("");
            setEmail("");
            setPhoneNumber("");
            setIssueType("");
            setMessage("");
            setErrors({});
          },
          (error) => {
            // Handle error
            console.log(error.text);
          }
        );
    }
  };

  return (
    <>
      <ToastContainer />
      <section className="contact-us-section">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-5 col-12 text-center my-4">
              <img src={Group_40907} alt="..." />
            </div>
            <div className="col-md col-12 my-4">
              <p className="contact-heading">We’d love to help</p>
              <p>Reach out and we’ll get in touch within 24 hours.</p>
              {/* <form onSubmit={handleSubmit}>
                <div className="row g-2">
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        required
                        placeholder="Your Name"
                        value={formData.name}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="name">Your Name</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="reply_to"
                        placeholder="Your Email"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="email">Your Email</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input
                        type="tel"
                        className="form-control"
                        id="mobileNumber"
                        name="mobileNumber"
                        placeholder="Your Mobile Number"
                        value={formData.mobileNumber}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="mobileNumber">Your Mobile Number</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating">
                      <select
                        className="form-select"
                        id="subject"
                        name="subject"
                        value={formData.subject}
                        onChange={handleInputChange}
                      >
                        <option value="Select Subject">Select Subject</option>
                        <option value="Credit Limit">Credit Limit</option>
                        <option value="Home Loan">Home Loan</option>
                        <option value="Business Loan">Business Loan</option>
                        <option value="Complaint">Complaint</option>
                      </select>
                      <label htmlFor="subject">Subject</label>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-floating">
                      <textarea
                        className="form-control"
                        id="query"
                        name="issueType"
                        rows="3"
                        placeholder="Describe Your Query"
                        value={formData.query}
                        onChange={handleInputChange}
                      ></textarea>
                      <label htmlFor="issueType">Describe Your Query</label>
                    </div>
                  </div>
                  <div className="col-md-12 form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="agreeToPrivacyPolicy"
                      name="agreeToPrivacyPolicy"
                      checked={formData.agreeToPrivacyPolicy}
                      onChange={handleInputChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="agreeToPrivacyPolicy"
                    >
                      <p className="agreement " style={{ color: "#414141" }}>
                        You agree to our friendly{" "}
                        <span style={{ color: "#33AB51" }}>
                          privacy policy.
                        </span>
                      </p>
                    </label>
                  </div>
                </div>
                <button className="knwbtn" type="submit">
                  Submit{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="26"
                    fill="currentColor"
                    className="bi bi-arrow-right-short"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                    />
                  </svg>
                </button>
              </form> */}

              <form ref={form} method="post" onSubmit={handleSubmit}>
                <div className="row ">
                  <div className="col-md-6 " style={{ marginTop: "10px" }}>
                    <TextField
                      id="name"
                      name="name"
                      label="Name"
                      variant="outlined"
                      fullWidth
                      value={name}
                      onChange={(e) => {
                        let { value } = e.target;
                        if (value.length > 0 && value[0] === " ") {
                          value = value.trimStart();
                        }
                        value = value.replace(/[^a-zA-Z ]/g, ""); // Remove numbers and special characters
                        setName(value);
                      }}
                      error={!!errors.name}
                      helperText={errors.name}
                      onKeyDown={(e) => {
                        if (/[^a-zA-Z ]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      inputProps={{
                        pattern: "[A-Za-z ]*",
                      }}
                    />
                  </div>
                  <div className="col-md-6" style={{ marginTop: "10px" }}>
                    <TextField
                      fullWidth
                      id="phoneNumber"
                      name="phoneNumber"
                      label="Phone Number"
                      variant="outlined"
                      value={phoneNumber}
                      onChange={(e) => {
                        const { value } = e.target;
                        if (
                          (/^[6789]\d*$/.test(value) || value === "") &&
                          value.length <= 10
                        ) {
                          setPhoneNumber(value);
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            phoneNumber: "",
                          }));
                        } else if (value.length > 10) {
                          setPhoneNumber(value.slice(0, 10)); // Truncate the input to 10 digits
                        } else {
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            phoneNumber: "Enter a valid phone number",
                          }));
                        }
                      }}
                      error={!!errors.phoneNumber}
                      helperText={errors.phoneNumber}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6" style={{ marginTop: "10px" }}>
                    <FormControl fullWidth error={!!errors.issueType}>
                      <InputLabel id="issueTypeLabel">
                        Product
                      </InputLabel>
                      <Select
                        labelId="issueTypeLabel"
                        id="issueType"
                        name="issueType"
                        value={issueType}
                        onChange={(e) => setIssueType(e.target.value)}
                        label="Select Your Query"
                      >
                        <MenuItem value={"Credit Limit"}>
                          {" "}
                          Credit Limit
                        </MenuItem>
                        <MenuItem value={"Home Loan"}>Home Loan</MenuItem>
                        <MenuItem value={"Business Loan"}>
                          Business Loan
                        </MenuItem>
                        <MenuItem value={"Personal Loan"}>
                          Personal Loan
                        </MenuItem>
                        <MenuItem value={"other"}>Other</MenuItem>{" "}
                      </Select>
                      <FormHelperText>{errors.issueType}</FormHelperText>
                    </FormControl>
                  </div>
                  <div className="col-md-6" style={{ marginTop: "10px" }}>
                    <TextField
                      id="email"
                      name="email"
                      label="E-mail"
                      variant="outlined"
                      fullWidth
                      value={email}
                      onChange={(e) => {
                        const { value } = e.target;
                        setEmail(value);

                        // Validate email format
                        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                        if (value && !emailRegex.test(value)) {
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            email: "Enter a valid email address",
                          }));
                        } else {
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            email: "",
                          }));
                        }
                      }}
                      error={!!errors.email}
                      helperText={errors.email}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12" style={{ marginTop: "10px" }}>
                    <TextField
                      fullWidth={true}
                      name="message"
                      id="outlined"
                      variant="outlined"
                      aria-label="minimum height"
                      multiline
                      rows={3}
                      placeholder="Message*"
                      value={message}
                      onChange={(e) => {
                        const { value } = e.target;
                        setMessage(value);

                        // Validate minimum character limit
                        if (value.length < 100) {
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            message: "Please enter at least 100 characters",
                          }));
                        } else {
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            message: "",
                          }));
                        }
                      }}
                      error={!!errors.message}
                      helperText={errors.message}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12" style={{ marginTop: "10px" }}>
                    <button
                      type="submit"
                      className="knwbtn"
                      style={{ marginTop: "20px" }}
                    >
                      Send
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="26"
                        height="26"
                        fill="#FFFFFF"
                        className="bi bi-arrow-right-short"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
